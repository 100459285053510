import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Thrid Library
import { SelectItem } from 'primeng/api';
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { AdjustmentListQuery } from './adjustmentlistquery';
import { StockAdjustment } from './stockadjustment';
import { ServiceCenter } from '../servicecenter/servicecenter';
import { StockItem } from '../stockitem/stockitem';

@Component({
    selector: 'i-stockadjustmentlist',
    templateUrl: './stockadjustmentlist.component.html'
})

export class StockAdjustmentListCP implements OnInit {
    @ViewChild('modal', { static: false }) modal: BsModalComponent;
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    adjustmentForm: FormGroup;
    query: AdjustmentListQuery;
    serviceCenters: ServiceCenter[];
    selectedChk: StockAdjustment[];
    servicecenterlist: SelectItem[];
    periods: SelectItem[];

    printMode: boolean = false;
    isNew: boolean = false;
    adjList: StockAdjustment[];
    AjTypes: SelectItem[];
    AjTypesFilter: SelectItem[];
    tempAdjustment: StockAdjustment;
    stockitems: SelectItem[];
    _stockitemsList: StockItem[];
    toastkey = "i-stockadjustmentlist";

    scChanged: any;
    siChanged: any;
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public fb: FormBuilder, public _ngZone: NgZone, public _dataChanged: DataChangedEvent) {
        this.pService.start();
        this.adjList = [];
        this.query = new AdjustmentListQuery();

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.serviceCenters, 'ScID');
                    }
                });
            }
        });

        this.siChanged = this._dataChanged.stockItemChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "stockitem") {
                        this.afterBroadCastEvent(sRobj, this._stockitemsList, 'SID');
                    }
                });
            }
        });

    }

    ngOnInit() {
        this.bindAjTypesForFilter();
        this.bindPeriod();
        this.query.AjType = 'All';
        this.query.Period = 'a';
        this.query.ScID = null;
        this.CreateForm();
        this.defaultDateTime();
        this.bindAjTypes();
        this.GetRequiredData().then(exp => {
            if (exp) {
                this.getAdjustmentList();
            }
        });

    }

    bindAjTypesForFilter(): void {
        this.AjTypesFilter = [];
        this.AjTypesFilter.push({ value: 'All', label: 'All' });
        this.AjTypesFilter.push({ value: 'Damage', label: 'Damage' });
        this.AjTypesFilter.push({ value: 'Lost', label: 'Lost' });
        this.AjTypesFilter.push({ value: 'Used', label: 'Used' });
        this.AjTypesFilter.push({ value: 'Gift', label: 'Gift' });
    }
    bindAjTypes(): void {
        this.AjTypes = [];
        this.AjTypes.push({ value: 'Damage', label: 'Damage' });
        this.AjTypes.push({ value: 'Lost', label: 'Lost' });
        this.AjTypes.push({ value: 'Used', label: 'Used' });
        this.AjTypes.push({ value: 'Gift', label: 'Gift' });
    }

    bindPeriod(): void {
        this.periods = [];
        this.periods.push({ value: 'a', label: 'Today' });
        this.periods.push({ value: 'b', label: 'This week' });
        this.periods.push({ value: 'c', label: 'This Month' });
        this.periods.push({ value: 'd', label: 'Within 2 Months' });
        this.periods.push({ value: 'e', label: 'Within 3 Months' });
        this.periods.push({ value: 'f', label: 'Custom Date' });
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let scList: any = [];
            let siList: any = [];
            this.httpService.getDataFJ(['api/servicecenter', 'api/stockitem']).subscribe(
                data => {
                    scList = data[0];
                    siList = data[1];
                },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {
                    if (scList) {
                        this.serviceCenters = scList.map(sc => Object.assign({}, sc));
                        this.setServiceCenterData(this.serviceCenters);
                    }

                    if (siList) {
                        this._stockitemsList = siList.map(si => Object.assign({}, si));
                        this.setStockItemData(this._stockitemsList);
                    }
                    this.pService.done();
                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpService.getData('api/servicecenter').subscribe(
                        data => { this.serviceCenters = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setServiceCenterData(this.serviceCenters); 
                            this.pService.done();
                         }
                    );

                } else if (objData.ObjType.toLowerCase() === "stockitem") {

                    this.httpService.getData('api/stockitem').subscribe(
                        data => { this._stockitemsList = data || []; },
                        err => { this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!'); 
                        this.pService.done(); 
                    },
                        () => { this.setStockItemData(this._stockitemsList); 
                            this.pService.done(); 
                        }
                    );

                }
            }
            else {

                if (objData.ObjType.toLowerCase() === "stockadjustment") {
                    if (this.query && this.query.FromDate && this.query.ToDate && (this.query.FromDate > objData.ObjType.AjDT || this.query.ToDate < objData.ObjType.AjDT)) {//want to update data by selected period
                        return;
                    }
                }

                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                } else if (objData.ObjType.toLowerCase() === "stockitem") {
                    this.setStockItemData(dataList);
                }
            }
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.servicecenterlist = [];
        this.servicecenterlist.push({ label: 'Select Center', value: null });
        if (dataList) {
            dataList.map(sc => this.servicecenterlist.push({ label: sc.ScN, value: sc.ScID }))
        }
    }

    setStockItemData(dataList: StockItem[]) {
        this.stockitems = [];
        this.stockitems.push({ label: 'Select stockitem ', value: null });
        if (dataList) {
            dataList.map(si => this.stockitems.push({ label: si.SN + " / " + si.Cat + " / " + si.SID, value: si.SID }));
        }
    }

    getAdjustmentList(): void {
        if (this.query && this.query.FromDate && this.query.ToDate) {
            this.pService.start();
        this.httpService.postData('api/get/stockadjustment', this.query).subscribe(
            data => { this.adjList = data; },
            err => {
                this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  from server!');
                this.pService.done();
            },
            () => {
                this.pService.done();
            });
        }
    }

    GetData(): void {
        this.pService.start();
        if (this.query.Period != 'f') this.defaultDateTime();
        this.getAdjustmentList();
    }

    refresh() {        
        this.GetRequiredData().then(exp => {
            if (exp) {
                this.getAdjustmentList();
            }
        });
    }

    printjob(): void { window.print(); }

    CreateForm(): void {
        this.adjustmentForm = this.fb.group({
            'AjID': new FormControl(''),
            'AjDT': new FormControl(new Date(), Validators.compose([Validators.required])),
            'SID': new FormControl(null, Validators.compose([Validators.required])),
            'Qty': new FormControl(0, Validators.compose([Validators.required])),
            'ScID': new FormControl(null, Validators.compose([Validators.required])),
            'AjType': new FormControl('Damage', Validators.compose([Validators.required])),
            'Rmk': new FormControl('')
        });
    }

    showModalDialog() {
        this.isNew = true;
        this.CreateForm();
        this.modal.open();
    }
    showDialogToEdit(adjustment: StockAdjustment) {
        this.isNew = false;
        this.CreateForm();
        this.modal.open();

        var date = new Date(adjustment.AjDT);

        this.adjustmentForm.patchValue({ AjID: adjustment.AjID, AjDT: date, SID: adjustment.SID, Qty: adjustment.Qty, ScID: adjustment.ScID, AjType: adjustment.AjType, Rmk: adjustment.Rmk });
    }
    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }
    findAdjustmentIndex(data, selected): number {
        for (var _index in data) {
            if (this.adjList[_index].AjID == selected.AjID) {
                return parseInt(_index);
            }
        }
    }

    findServiceCenter() {
        for (var i = 0; i < this.servicecenterlist.length; i++) {
            if (this.tempAdjustment.ScID == this.serviceCenters[i].ScID) {
                this.tempAdjustment.ScN = this.serviceCenters[i].ScN;
                break;
            }
        }
    }

    findStock(): void {
        for (let si of this._stockitemsList) {
            if (this.tempAdjustment.SID == si.SID) {
                this.tempAdjustment.SN = si.SN;
                this.tempAdjustment.Cat = si.Cat;
                break;
            }
        }
    }

    save() {
        if (this.adjustmentForm != undefined) {
            this.tempAdjustment = new StockAdjustment();
            this.tempAdjustment = this.adjustmentForm.value;

            this.findStock();
            this.findServiceCenter();

            this.httpService.postData('api/stockadjustment', this.tempAdjustment).
                subscribe(
                    data => {
                        if (this.isNew) {
                            this.tempAdjustment.AjID = data.AjID;
                            this.adjList.push(this.tempAdjustment);
                        }
                        else {
                            var index = this.findAdjustmentIndex(this.adjList, this.adjustmentForm.value);
                            this.adjList[index] = this.tempAdjustment;
                        }
                    },
                    err => {
                        this.httpService.showToast(this.toastkey, 'error', 'Error occurs while inserting/updating data!');
                        this.pService.done();
                    },
                    () => {
                        this.httpService.showToast(this.toastkey, 'success', 'Successfully inserted/updated');
                        this.modal.close();
                    });
        }
    }
    
    deleteData() {
        this.httpService.postData('api/stockadjustment/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    defaultDateTime() {
        this.query.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.query.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
    }
}   