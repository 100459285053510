import { Component, OnInit, OnDestroy, OnChanges, AfterViewInit, AfterContentInit, ViewChild, NgZone } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { NgProgressService } from "ng2-progressbar";
import { SelectItem } from 'primeng/api';

//Service
import { HttpServices } from '../service/httpservices';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { JobListNewQuery } from '../joblistnew/joblistnewquery';
import { TechDashQuery } from './techdashquery';
import { JobListNewDeptQuery } from '../joblistnew/joblistnewDeptquery';

@Component({
    selector: 'tech-dashboard',
    templateUrl: './tech_dashboard.component.html'
})

export class TechDashboardCP implements OnInit, OnDestroy {
    @ViewChild('jobDetailListModal', { static: false }) jobDetailListModal: BsModalComponent;

    public dquery: TechDashQuery;
    public filteredDataList: any[] = [];//to calculate total
    public dataList: any[];
    public srObjT: any;
    public srObjSM: any;
    public joblistnewqry: JobListNewDeptQuery;
    public title: string = '';
    public isJobDetailList: boolean = false;
    public search: string = '';
    public srObjJ: any;
    public toastkey = "tech-dashboard";
    public servicecenterddown: SelectItem[] = [];
    public scList: any = null;
    selectedServiceCenter: number;
    selectedDept;


    constructor(private pService: NgProgressService, public httpservice: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.dquery = new TechDashQuery();
        this.dquery.PType = null;
        this.dquery.Period = 'a';
        this.dquery.SCID = null;
        this.dquery.DID = null;
        this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
        this.dquery.SearchText = '';

        this.joblistnewqry = new JobListNewDeptQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);

        // this.srObjT = this._dataChanged.jobTStatusChanged.subscribe(
        //     srDataT => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );

        // this.srObjSM = this._dataChanged.jobSmStatusChanged.subscribe(
        //     srDataSM => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );

        // this.srObjJ = this._dataChanged.jobChanged.subscribe(
        //     srData => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );
        this.servicecenterddown.push({ label: '- Select Service Center -', value: null });
    }

    ngOnInit() {
        this.getRequiredData();
        this.getDataList();
    };

    ngOnDestroy() {
        this.dquery =
            this.dataList =
            this.joblistnewqry =
            this.dquery = undefined;
        if (this.srObjT !== undefined) this.srObjT.unsubscribe();
        if (this.srObjSM !== undefined) this.srObjSM.unsubscribe();
        if (this.srObjJ !== undefined) this.srObjJ.unsubscribe();
    }

    getRequiredData(): void {
        this.httpservice.getDataFJ(['api/getsc']).subscribe(
            data => {
                this.scList = data[0];
                this.scList.map(x => {
                    this.servicecenterddown.push({ label: x.Name, value: x.ID });
                })
                //this.pService.done();
            },
            err => { this.httpservice.showToast(this.toastkey, 'error', err); },
            () => {
            });
    }

    refresh(){        
        this.getDataList();
    }

    getDataList(): void {
        if (this.dquery && this.dquery.FromDate && this.dquery.ToDate) {
            this.pService.start();
            this.httpservice.postData('api/techniciandashboard', this.dquery).subscribe(
                exp => {
                    this.dataList = exp;
                },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', err);
                    this.pService.done();
                },
                () => {
                    this.pService.done();
                });
        } else {
            this.pService.done();
        }
    }

    filterChanged() {
        this.dquery.SCID = this.selectedServiceCenter;
        //this.pService.start();
        this.getDataList();
    }

    filterChangedDepartment() {
        this.dquery.DID = this.selectedDept;
        //this.pService.start();
        this.getDataList();
    }

    periodChanged(): void {
        //this.pService.start();
        console.log("periodchange ", this.dquery.Period);
        if (this.dquery.Period === 'f') {
            this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
            this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
            this.getDataList();

        } else this.getDataList();

    }

    onSelectDate(): void {
        this.getDataList();
    }

    exportToCSV() {
        this.dquery.SearchText = this.search;
        this.httpservice.postData('api/exporttechniciandashboard', this.dquery).subscribe(
            data => { },
            err => { },
            () => {
                this.httpservice.DirectLocToWindow("techniciandashboard");
            }
        );
    }

    cellClickTotal(colfield, count , scenter) {
        if (this.search || count === 0) return;
        this.joblistnewqry.departmentId = this.dquery.DID;
        this.joblistnewqry.servicecenterId = scenter.ScID;
        this.joblistnewqry.servicetypeId = null;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        this.joblistnewqry.noTaken = true;
        this.joblistnewqry.noSDone = true;
        this.joblistnewqry.jat = null;
        
        switch (colfield) {
            case 'WaitPart': {
                this.joblistnewqry.jobstatusId = 15;
                this.title = 'Job Detail List (WaitPart)';
                this.isJobDetailList = true;
                break;
            }
            case 'WorkInProgress': {
                this.joblistnewqry.jobstatusId = 5;
                this.title = 'Job Detail List (WorkInProgress)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitingCustomerReply': {
                this.joblistnewqry.jobstatusId = 7;
                this.title = 'Job Detail List (WaitingCustomerReply)';
                this.isJobDetailList = true;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6;
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer': {
                this.joblistnewqry.jobstatusId = 18;
                this.title = 'Job Detail List (CancelCustomer)';
                this.isJobDetailList = true;
                break;
            }
            case 'Irreparable': {
                this.joblistnewqry.jobstatusId = 10;
                this.title = 'Job Detail List (Unable to repair)';
                this.isJobDetailList = true;
                break;
            }
            case 'SDone': {
                this.joblistnewqry.jobstatusId = 2;
                //this.joblistnewqry.noSDone = 
                this.title = 'Job Detail List (Service Manager Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'TDone': {
                this.joblistnewqry.jobstatusId = 3;
                this.title = 'Job Detail List (Technical Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'Taken': {
                this.joblistnewqry.jobstatusId = 4;
                this.title = 'Job Detail List (Taken)';
                this.isJobDetailList = true;
                break;
            }
            case 'FOC': {
                this.joblistnewqry.jobstatusId = 8;
                this.title = 'Job Detail List (FOC)';
                this.isJobDetailList = true;
                break;
            }
            case 'Irreparable2': {
                this.joblistnewqry.jobstatusId = 110; //custom value
                this.title = 'Job Detail List (Unable to repair)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer2': {
                this.joblistnewqry.jobstatusId = 118;//custom value
                this.title = 'Job Detail List (CancelCustomer)';
                this.isJobDetailList = true;                
                break;
            }
            case 'Total': {
                this.joblistnewqry.noTaken = false;
                this.joblistnewqry.noSDone = false;
                this.joblistnewqry.jobstatusId = 20;//custom value
                this.title = 'Job Detail List (Total)';
                this.isJobDetailList = true;                
                break;
            }

        }
        this.jobDetailListModal.open();
    }

    cellClick(colfield, rdata, scenter) {
        if (rdata === null || rdata === undefined || rdata[colfield] === 0) return;
        this.joblistnewqry.departmentId = null;
        this.joblistnewqry.servicecenterId = scenter.ScID;
        this.joblistnewqry.servicetypeId = null;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        this.joblistnewqry.noTaken = true;
        this.joblistnewqry.noSDone = true;
        this.joblistnewqry.jat = rdata.UN;
        
        switch (colfield) {
            case 'WaitPart': {
                this.joblistnewqry.jobstatusId = 15;
                this.title = 'Job Detail List (WaitPart)';
                this.isJobDetailList = true;
                break;
            }
            case 'WorkInProgress': {
                this.joblistnewqry.jobstatusId = 5;
                this.title = 'Job Detail List (WorkInProgress)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitingCustomerReply': {
                this.joblistnewqry.jobstatusId = 7;
                this.title = 'Job Detail List (WaitingCustomerReply)';
                this.isJobDetailList = true;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6;
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer': {
                this.joblistnewqry.jobstatusId = 18;
                this.title = 'Job Detail List (CancelCustomer)';
                this.isJobDetailList = true;
                break;
            }
            case 'Irreparable': {
                this.joblistnewqry.jobstatusId = 10;
                this.title = 'Job Detail List (Unable to repair)';
                this.isJobDetailList = true;
                break;
            }
            case 'SDone': {
                this.joblistnewqry.jobstatusId = 2;
                //this.joblistnewqry.noSDone = 
                this.title = 'Job Detail List (Service Manager Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'TDone': {
                this.joblistnewqry.jobstatusId = 3;
                this.title = 'Job Detail List (Technical Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'Taken': {
                this.joblistnewqry.jobstatusId = 4;
                this.title = 'Job Detail List (Taken)';
                this.isJobDetailList = true;
                break;
            }
            case 'FOC': {
                this.joblistnewqry.jobstatusId = 8;
                this.title = 'Job Detail List (FOC)';
                this.isJobDetailList = true;
                break;
            }
            case 'Irreparable2': {
                this.joblistnewqry.jobstatusId = 110; //custom value
                this.title = 'Job Detail List (Unable to repair)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer2': {
                this.joblistnewqry.jobstatusId = 118;//custom value
                this.title = 'Job Detail List (CancelCustomer)';
                this.isJobDetailList = true;                
                break;
            }
            case 'Total': {
                this.joblistnewqry.noTaken = false;
                this.joblistnewqry.noSDone = false;
                this.joblistnewqry.jobstatusId = 20;//custom value
                this.title = 'Job Detail List (Total)';
                this.isJobDetailList = true;                
                break;
            }

        }
        this.jobDetailListModal.open();
    }

    jDListModalClose() {
        this.joblistnewqry = new JobListNewDeptQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);
        this.title = '';
        this.isJobDetailList = false;
        this.jobDetailListModal.close();
    }

    jobInfosValue(jobinfos: any) {
        if (jobinfos == null || jobinfos == undefined) return;        
        if (this.search == null || this.search == undefined) this.search = '';
        let filteredData = [];
        filteredData = jobinfos.filter(j => (j.Name ? j.Name.trim() : j.UN).toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1)
        this.filteredDataList = filteredData.map(a => Object.assign({}, a));
        return filteredData;
    }

    calculateTotal(propName: string, totalPropName: string, data: any) {
        if (this.search == "" || this.search == null || this.search == undefined) {
            return data[totalPropName];
        } else {
            let total = 0;
            if (this.filteredDataList != null && this.filteredDataList != undefined && this.filteredDataList.length > 0) {
                total = this.filteredDataList.map(a => a[propName]).reduce(function (a, b) {
                    return a + b;
                });
            }

            return total;
        }
    }
}