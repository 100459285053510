import { Component, OnInit, OnDestroy, OnChanges, AfterViewInit, AfterContentInit, ViewChild, NgZone } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';
import { DataChangedEvent } from '../service/datachangedevent';

//Model
import { DashQuery } from '../dashboard/dashquery';
import { JobListNewQuery } from '../joblistnew/joblistnewquery';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
    selector: 'main-dashboard',
    templateUrl: './main-dashboard.component.html'
})

export class MainDashboardCP implements OnInit, OnDestroy {
    @ViewChild('jobDetailListModal', { static: false }) jobDetailListModal: BsModalComponent;

    public dquery: DashQuery;
    public filteredDataList: any[] = [];//to calculate total
    public dataList: any[];
    public srObjT: any;
    public srObjSM: any;
    public joblistnewqry: JobListNewQuery;
    public title: string = '';
    public isJobDetailList: boolean = false;
    public search: string = '';
    public srObjJ: any;
    public toastkey = "main-dashboard";
    public servicecenterddown: SelectItem[] = [];
    public scList: any = null;
    selectedServiceCenter: number;
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService ,public httpservice: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.pService.start();
        this.dquery = new DashQuery();
        this.dquery.PType = 'st';
        this.dquery.Period = 'a';
        this.dquery.Selected = null;
        this.dquery.SCID = null;
        this.dquery.STID = null;
        this.dquery.DID = null;
        this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
        this.dquery.SearchText = '';

        this.joblistnewqry = new JobListNewQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);

        // this.srObjT = this._dataChanged.jobTStatusChanged.subscribe(
        //     srDataT => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );

        // this.srObjSM = this._dataChanged.jobSmStatusChanged.subscribe(
        //     srDataSM => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );

        // this.srObjJ = this._dataChanged.jobChanged.subscribe(
        //     srData => { this._ngZone.run(() => { this.getDataList(); }) },
        //     err => {
        //         this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading  mcb from server!');
        //         this.pService.done();
        //     },
        //     () => { }
        // );
       
    }

    ngOnInit() {
        this.getRequiredData();
        this.getDataList();
    };

    ngOnDestroy() {
        this.dquery =
            this.dataList =
            this.joblistnewqry =
            this.dquery = undefined;
        if (this.srObjT !== undefined) this.srObjT.unsubscribe();
        if (this.srObjSM !== undefined) this.srObjSM.unsubscribe();
        if (this.srObjJ !== undefined) this.srObjJ.unsubscribe();
    }

    refresh(){            
        this.getDataList();
    }

    valChangedServiceCenter() {
        this.dquery.SCID = this.selectedServiceCenter;
    }

    getDataList(): void {
        if (this.dquery && this.dquery.SCID != null && this.dquery.SCID != undefined && this.dquery.FromDate && this.dquery.ToDate) {
            this.pService.start();  
            var obj = Object.assign({}, this.dquery);
            obj.SCID = obj.SCID == -1 ? null : obj.SCID;
            this.httpservice.postData('api/maindashboardtable', obj).subscribe(
                exp => {
                    this.dataList = exp;
                },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', err); 
                    this.pService.done();
                },
                () => {
                    this.pService.done();
                });
        } else {
            this.dataList = [];
            this.pService.done();
        }
    }

    getRequiredData(): void {        
        this.httpservice.getDataFJ(['api/getsc']).subscribe(
            data => {
                this.scList = data[0];
                this.scList.map(x => {
                    this.servicecenterddown.push({ label: x.Name, value: x.ID });
                })
                this.servicecenterddown.push({ label: '- All Service Center -', value: -1 });
                //this.pService.done();
                
            },
            err => { this.httpservice.showToast(this.toastkey, 'error', err); },
            () => {
        });
    }

    periodChanged(): void {
        //this.pService.start();
        if (this.dquery.Period === 'f') {
            this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
            this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
            //this.getDataList();

        } else {
            //this.getDataList();
        }
    }

    onSelectDate(): void {
        //this.getDataList();
    }

    exportToCSV() {
        this.dquery.SearchText = this.search;
        var obj = Object.assign({}, this.dquery);
        obj.SCID = obj.SCID == -1 ? null : obj.SCID;        
        this.httpservice.postData('api/exportmaindashboardtable', obj).subscribe(
            data => { },
            err => { },
            () => {
                //window.location.href = this.httpservice._url + 'excel/maindashboardjobs.csv';
                this.httpservice.DirectLocToWindow("maindashboardjobs");
            }
        );
    }

    cellClickTotal(colfield, count , scenter){        
        if (this.search || count === 0) return;
        this.joblistnewqry.departmentId = null;
        this.joblistnewqry.servicecenterId = scenter.ScID;
        this.joblistnewqry.servicetypeId = null;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        this.joblistnewqry.noTaken = false;
        this.joblistnewqry.noSDone = false;
        switch (colfield) {
            
            case 'WaitingCustomerReply': {
                this.joblistnewqry.jobstatusId = 7;
                this.title = 'Job Detail List (WaitingCustomerReply)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitPart': {
                this.joblistnewqry.jobstatusId = 15;
                this.title = 'Job Detail List (WaitPart)';
                this.isJobDetailList = true;
                break;
            }
            case 'ReceivedJob': {
                this.joblistnewqry.jobstatusId = 1;
                this.title = 'Job Detail List (Job Receive)';
                this.isJobDetailList = true;
                break;
            }
            case 'WorkInProgress': {
                this.joblistnewqry.jobstatusId = 5;
                this.title = 'Job Detail List (WP)';
                this.isJobDetailList = true;
                break;
            }
            case 'SDone': {
                this.joblistnewqry.noTaken = true;
                this.joblistnewqry.noSDone = true;
                this.joblistnewqry.jobstatusId = 2;
                this.title = 'Job Detail List (Service Manager Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'TDone': {
                this.joblistnewqry.noTaken = true;
                this.joblistnewqry.noSDone = true;
                this.joblistnewqry.jobstatusId = 3;
                this.title = 'Job Detail List (Technician Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'Taken': {
                this.joblistnewqry.jobstatusId = 4;
                this.title = 'Job Detail List (Taken)';
                this.isJobDetailList = true;
                break;
            }
            case 'FOC': {
                this.joblistnewqry.jobstatusId = 8;
                this.title = 'Job Detail List (FOC)';
                this.isJobDetailList = true;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6;
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                break;
            }
            case 'Irreparable': {
                this.joblistnewqry.jobstatusId = 110; //custom value
                this.title = 'Job Detail List (Unable to repair)';
                this.isJobDetailList = true;
                break;
            }
            case 'CancelCustomer': {
                this.joblistnewqry.jobstatusId = 118;
                this.title = 'Job Detail List (CustomerCancel)';
                this.isJobDetailList = true;
                break;
            }

        }
        this.jobDetailListModal.open();
    }

    cellClick(colfield, rdata, scenter) {
        if (rdata === null || rdata === undefined || rdata[colfield] === 0) return;
        this.joblistnewqry.departmentId = null;
        this.joblistnewqry.servicecenterId = scenter.ScID;
        this.joblistnewqry.servicetypeId = rdata.STID;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        this.joblistnewqry.noTaken = true;
        this.joblistnewqry.noSDone = true;       

        switch (colfield) {
            case 'CancelCustomer': {
                this.joblistnewqry.jobstatusId = 118;
                this.title = 'Job Detail List (CustomerCancel)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitingCustomerReply': {
                this.joblistnewqry.jobstatusId = 7;
                this.title = 'Job Detail List (WaitingCustomerReply)';
                this.isJobDetailList = true;
                break;
            }
            case 'WaitPart': {
                this.joblistnewqry.jobstatusId = 15;
                this.title = 'Job Detail List (WaitPart)';
                this.isJobDetailList = true;
                break;
            }
            case 'ReceivedJob': {
                this.joblistnewqry.jobstatusId = 1;
                this.title = 'Job Detail List (Job Receive)';
                this.isJobDetailList = true;
                break;
            }
            case 'WorkInProgress': {
                this.joblistnewqry.jobstatusId = 5;
                this.title = 'Job Detail List (WP)';
                this.isJobDetailList = true;
                break;
            }
            case 'SDone': {
                this.joblistnewqry.noTaken = true;
                this.joblistnewqry.noSDone = true;
                this.joblistnewqry.jobstatusId = 2;
                this.title = 'Job Detail List (Service Manager Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'TDone': {
                this.joblistnewqry.noTaken = true;
                this.joblistnewqry.noSDone = true;
                this.joblistnewqry.jobstatusId = 3;
                this.title = 'Job Detail List (Technician Done)';
                this.isJobDetailList = true;
                break;
            }
            case 'Taken': {
                this.joblistnewqry.jobstatusId = 4;
                this.title = 'Job Detail List (Taken)';
                this.isJobDetailList = true;
                break;
            }
            case 'FOC': {
                this.joblistnewqry.jobstatusId = 8;
                this.title = 'Job Detail List (FOC)';
                this.isJobDetailList = true;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6;
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                break;
            }
            case 'Irreparable': {
                this.joblistnewqry.jobstatusId = 110; //custom value
                this.title = 'Job Detail List (Unable to repair)';
                this.isJobDetailList = true;
                break;
            }

        }

        // if (this.joblistnewqry.jobstatusId == 110) {
        //     this.joblistnewqry.noSDone = false;
        // } else if (this.joblistnewqry.jobstatusId == 118) {
        //     this.joblistnewqry.noSDone = false;
        // }            
           
        // if (this.joblistnewqry.jobstatusId == 2 || this.joblistnewqry.jobstatusId == 3 ||
        //     this.joblistnewqry.jobstatusId == 17 || this.joblistnewqry.jobstatusId == 5 ||
        //     this.joblistnewqry.jobstatusId == 7 || this.joblistnewqry.jobstatusId == 9 ||
        //     this.joblistnewqry.jobstatusId == 10 || this.joblistnewqry.jobstatusId == 11 ||
        //     this.joblistnewqry.jobstatusId == 110 || this.joblistnewqry.jobstatusId == 18 ||
        //     this.joblistnewqry.jobstatusId == 118 || this.joblistnewqry.jobstatusId == 15) {  //(Taken မပါရပါ။)
        //     this.joblistnewqry.noTaken = true;
        // }else if(this.joblistnewqry.jobstatusId == 4){
        //     this.joblistnewqry.noTaken = false;
        // }
        // else {
        //     this.joblistnewqry.noTaken = null;
        // }
        this.jobDetailListModal.open();
    }

    jDListModalClose() {
        this.joblistnewqry = new JobListNewQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);
        this.title = '';
        this.isJobDetailList = false;
        this.jobDetailListModal.close();
    }

    jobInfosValue(jobinfos: any) {
        if (jobinfos == null || jobinfos == undefined) return;

        if (this.search == null || this.search == undefined) this.search = '';
        let filteredData = [];
        filteredData = jobinfos.filter(j => (j.STN.trim()).toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1)
        this.filteredDataList = filteredData.map(a=> Object.assign({},a));
        return filteredData;
    }
    
    calculateTotal(propName:string, totalPropName:string, data: any){
        if(this.search == "" || this.search == null || this.search == undefined){
            return data[totalPropName];
        }else{
            var total = 0;
            if(this.filteredDataList!= null && this.filteredDataList != undefined && this.filteredDataList.length > 0){
                total = this.filteredDataList.map(a => a[propName]).reduce(function(a, b)
                {
                    return a + b;
                });
            }
            data[totalPropName] = total;
            return total;
        }
    }
}