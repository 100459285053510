import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { StockTransfer } from './stocktransfer';
import { TransferListQuery } from './transferlistquery';

@Component({
    selector: 'i-transferlist',
    templateUrl: './transferlist.component.html'
})
export class TransferListCP implements OnInit {
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    public periods: SelectItem[];
    public stfList: StockTransfer[] = [];
    public query: TransferListQuery;
    public printMode: boolean = false;
    public selectedChk: StockTransfer[] = [];
    public selectedData: StockTransfer;
    public toastkey = "i-transferlist";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router) {
        this.pService.start();
        this.query = new TransferListQuery();
        this.selectedData = new StockTransfer();
    }
    ngOnInit() {
        this.bindPeriod();
        this.query.Period = 'a';
        this.defaultDateTime();
        this.getTransferList();
    }

    bindPeriod(): void {
        this.periods = [];
        this.periods.push({ value: 'a', label: 'Today' });
        this.periods.push({ value: 'b', label: 'This week' });
        this.periods.push({ value: 'c', label: 'This Month' });
        this.periods.push({ value: 'd', label: 'Within 2 Months' });
        this.periods.push({ value: 'e', label: 'Within 3 Months' });
        this.periods.push({ value: 'f', label: 'Custom Date' });
    }
    getTransferList(): void {
        if (this.query && this.query.FromDate && this.query.ToDate) {
            this.pService.start();
            this.httpService.postData('api/get/stocktransferlist', this.query).subscribe(
                data => { this.stfList = data; },
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading  from server!');
                    this.pService.done();
                },
                () => {
                    this.pService.done();
                });
        }
    }

    periodChanges(): void {

        this.defaultDateTime();

        this.getTransferList();
    }

    printjob(): void {
        window.print();
    }

    onSelectDate(): void {
        this.getTransferList();
    }
    goDetail(stf): void {
        let link = ['/stocktransferdetail', stf.TID];
        this.router.navigate(link);
    }

    Refresh(): void {
        
        this.getTransferList();
    }
    showDialogToDelete(): void {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    deleteData() {
        this.httpService.postData('api/stocktransfer/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                },
                () => {
                    this.doneDeleteProcess('success', 'Successfully deleted');
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    findIndexOfStock(selecteddata: StockTransfer): number {
        for (var _index in this.stfList) {
            if (this.stfList[_index].TID === selecteddata.TID) {
                return parseInt(_index);
            }
        }
    }

    defaultDateTime() {
        this.query.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.query.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
    }
}