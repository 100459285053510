import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import {NgProgressService} from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { StockIn } from './stockin';
import { StockInListQuery } from './stockinlistquery';

@Component({
    selector: 'i-stockinlist',
    templateUrl: './stockinlist.component.html'
})

export class StockInListCP implements OnInit {
    @ViewChild('deleteModal', { static: false }) deleteModal: BsModalComponent;
    public stockInList: StockIn[] = [];
    public selectedChk: StockIn[] = [];
    public printMode: boolean = false;
    public periods: SelectItem[];
    public query: StockInListQuery = new StockInListQuery();
    public toastkey = "i-stockinlist";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    
    constructor(private pService: NgProgressService ,public httpService: HttpServices, public router: Router, public _ngZone: NgZone) {
        this.pService.start();
    }

    ngOnInit() {
        this.bindPeriod();
        this.query.Period = 'a';
        this.defaultDateTime();

        this._ngZone.run(() => this.getStockList());
    }

    getStockList(): void {
        if (this.query && this.query.FromDate && this.query.ToDate) {
            this.pService.start();
            this.httpService.postData('api/getstockin', this.query).subscribe(
                data => this.stockInList = data,
                err => {
                    this.httpService.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                },
                () => {
                    this.pService.done();
                });
        }
    }

    bindPeriod(): void {
        this.periods = [];
        this.periods.push({ value: 'a', label: 'Today' });
        this.periods.push({ value: 'b', label: 'This week' });
        this.periods.push({ value: 'c', label: 'This Month' });
        this.periods.push({ value: 'd', label: 'Within 2 Months' });
        this.periods.push({ value: 'e', label: 'Within 3 Months' });
        this.periods.push({ value: 'f', label: 'Custom Date' });
    }
    periodChanges(): void {
        this.defaultDateTime();
        this.getStockList();
    }
    onSelectDate(): void {
        this.getStockList();
    }
    Refresh(): void {
        
        this.getStockList();
    }

    findIndexOfStockIn(selecteddata: StockIn): number {
        for (var _index in this.stockInList) {
            if (this.stockInList[_index].SIID == selecteddata.SIID) {
                return parseInt(_index);
            }
        }
    }

    editStockIn(stockin: StockIn): void {
        var link = ['purchase-entry', stockin.SIID];
        this.router.navigate(link);
    }

    createStockIn(): void {
        var link = ['purchase-entry'];
        this.router.navigate(link);
    }

    createStockInExcel(): void{
        var link = ['stockin'];
        this.router.navigate(link);
    }

    deleteData() {
        this.httpService.postData('api/stockin/multidelete/', this.selectedChk).
            subscribe(
                data => {
                    this.httpService.showUnsuccessDelMsg(this.toastkey, data);
                },
                err => {
                    this.doneDeleteProcess('error', 'Error occurs while deleting data!');
                    this.Refresh();
                },
                () => {
                    this.Refresh();
                    this.doneDeleteProcess('success', 'Successfully deleted');
                    
                });
    }

    deleteModalClose() {
        this.deleteModal.close();
        this.httpService.AddClassToModal(true);
    }

    doneDeleteProcess(header: string, msg: string) {
        this.selectedChk = null;
        this.deleteModalClose();
        this.httpService.showToast(this.toastkey, header, msg);
    }

    showDialogToDelete() {
        if (this.selectedChk != null && this.selectedChk != undefined && this.selectedChk.length > 0) {
            this.deleteModal.open();
        } else {
            this.httpService.showToast(this.toastkey, 'warn', 'Need to select row!');
        }
    }

    printjob(): void { window.print(); }

    defaultDateTime() {
        this.query.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.query.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
    }
}