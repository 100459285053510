import { Component, OnInit, OnDestroy, ViewChild, NgZone } from '@angular/core';

// Thrid Library
import { BsModalComponent } from 'ng2-bs3-modal';
import { SelectItem } from 'primeng/api';
import { NgProgressService } from "ng2-progressbar";

//Service
import { HttpServices } from '../service/httpservices';

//Model
import { DashQuery } from './dashquery';
import { DataChangedEvent } from '../service/datachangedevent';
import { JobListNewQuery } from '../joblistnew/joblistnewquery';
import { ServiceCenter } from '../servicecenter/servicecenter';
import { ServiceType } from '../servicetype/servicetype';
import { Department } from '../department/department';

@Component({
    selector: 'i-dashboard',
    templateUrl: './dashboard.component.html'
})

export class DashboardCP implements OnInit, OnDestroy {
    @ViewChild('barChart', { static: false }) barChart;
    @ViewChild('pieChart', { static: false }) pieChart;
    @ViewChild('jobDetailListModal', { static: false }) jobDetailListModal: BsModalComponent;

    public periods: SelectItem[] = [{ value: 'a', label: 'Today' },
    { value: 'b', label: 'This week' },
    { value: 'c', label: 'This Month' },
    { value: 'd', label: 'Within 2 Months' },
    { value: 'e', label: 'Within 3 Months' },
    { value: 'f', label: 'Custom Date' }];

    types: SelectItem[] = [{ value: 'st', label: 'Service Type' }, { value: 'sc', label: 'Service Center' }]

    oServiceCenterList: ServiceCenter[] = [];
    serviceCenterList: SelectItem[] = [];

    public oServiceTypeList: ServiceType[] = [];
    public serviceTypeList: SelectItem[] = [];

    oDepartmentList: Department[] = [];
    departmentList: SelectItem[] = [];

    selectLists: SelectItem[] = [];
    dataList: any[] = [];
    public filterDataList: any[] = [];

    dquery: DashQuery;
    joblistnewqry: JobListNewQuery;
    title: string = '';
    public search: string = '';
    isJobDetailList: boolean = false;

    barData: any;
    pieData: any;
    srObjT: any;
    srObjSM: any;
    startPBar: any;
    donePBar: any;

    srObjJ: any;
    scChanged: any;
    stChanged: any;
    depChanged: any;
    toastkey = "i-dashboard";
    //@ViewChild(NgProgressComponent) pService: NgProgressComponent;
    constructor(private pService: NgProgressService, public httpservice: HttpServices, public _dataChanged: DataChangedEvent, public _ngZone: NgZone) {
        this.dquery = new DashQuery();
        this.dquery.PType = 'st';
        this.dquery.Period = 'a';
        this.dquery.Selected = null;
        this.dquery.SCID = null;
        this.dquery.STID = null;
        this.dquery.DID = null;
        this.dquery.SearchText = '';
        this.DefaultDateTime();


        this.joblistnewqry = new JobListNewQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);

        //barchart data set
        this.barData = {
            labels: [],
            datasets: [
                {
                    label: '',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: []
                },
                {
                    label: '',
                    backgroundColor: '#9CCC65',
                    borderColor: '#7CB342',
                    data: []
                }]
        }

        //pie data set
        this.pieData = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56"
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                        "#FFCE56"
                    ]
                }]
        };

        // this.srObjT = this._dataChanged.jobTStatusChanged.subscribe((sRobj) => { 
        //     this._ngZone.run(() => { 
        //         this.getDataList(); 
        //         this.refreshChart(); 
        //     });
        // });

        // this.srObjSM = this._dataChanged.jobSmStatusChanged.subscribe((sRobj) => { 
        //     this._ngZone.run(() => { 
        //         this.getDataList(); 
        //         this.refreshChart(); 
        //     });
        // });

        // this.srObjJ = this._dataChanged.jobChanged.subscribe((sRobj) => { 
        //     this._ngZone.run(() => { 
        //         this.getDataList(); 
        //         this.refreshChart(); 
        //     });
        // });

        this.scChanged = this._dataChanged.serviceCenterChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicecenter") {
                        this.afterBroadCastEvent(sRobj, this.oServiceCenterList, 'ScID');
                    }
                });
            }
        });

        this.stChanged = this._dataChanged.serviceTypeChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "servicetype") {
                        this.afterBroadCastEvent(sRobj, this.oServiceTypeList, 'STID');
                    }
                });
            }
        });

        this.depChanged = this._dataChanged.departmentChanged.subscribe((sRobj) => {
            if (sRobj && sRobj.ObjType) {
                this._ngZone.run(() => {
                    if (sRobj.ObjType.toLowerCase() === "department") {
                        this.afterBroadCastEvent(sRobj, this.oDepartmentList, 'DID');
                    }
                });
            }
        });
    }

    ngOnInit() {
        this.DefaultDateTime();
        this.GetRequiredData().then(exp => {
            if (exp) {
                this.getDataList();
            }
        });
    };

    refresh() {
        if (this.dquery && this.dquery.FromDate && this.dquery.ToDate) {
            this._ngZone.run(() => {
                this.pService.start();
                this.getDataList();
                this.refreshChart();
            });
        }
    }

    GetRequiredData(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let scList: any = [];
            let stList: any = [];
            let dList: any = [];
            this.httpservice.getDataFJ(['api/servicecenter', 'api/servicetype', 'api/department']).subscribe(
                data => {
                    scList = data[0];
                    stList = data[1];
                    dList = data[2];
                },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                    this.pService.done();
                    resolve(false);
                },
                () => {

                    if (scList) {
                        this.oServiceCenterList = scList.map(d => Object.assign({}, d));
                        this.setServiceCenterData(this.oServiceCenterList);
                    }

                    if (stList) {
                        this.oServiceTypeList = stList.map(d => Object.assign({}, d));
                        this.setSelectLists(this.oServiceTypeList);
                        this.setServiceTypeData(this.oServiceTypeList);
                    }

                    if (dList) {
                        this.oDepartmentList = dList.map(d => Object.assign({}, d));
                        this.setDepartmentData(this.oDepartmentList);
                    }

                    resolve(true);
                }
            );
        });
    }

    afterBroadCastEvent(objData, dataList, id) {
        if (objData && objData.Obj && dataList) {
            let ind = dataList.findIndex(temp => temp[id] === objData.Obj[id]);
            if (objData.Msg === 'deleted') {
                if (objData.ObjType.toLowerCase() === "department") {

                    this.httpservice.getData('api/department').subscribe(
                        data => { this.oDepartmentList = data || []; },
                        err => {
                            this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.setDepartmentData(this.oDepartmentList);
                            this.pService.done();
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicetype") {

                    this.httpservice.getData('api/servicetype').subscribe(
                        data => { this.oServiceTypeList = data || []; },
                        err => {
                            this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.setServiceTypeData(this.oServiceTypeList);
                            this.pService.done();
                        }
                    );

                } else if (objData.ObjType.toLowerCase() === "servicecenter") {

                    this.httpservice.getData('api/servicecenter').subscribe(
                        data => { this.oServiceCenterList = data || []; },
                        err => {
                            this.httpservice.showToast(this.toastkey, 'error', 'Error occurs while loading data from server!');
                            this.pService.done();
                        },
                        () => {
                            this.setServiceCenterData(this.oServiceCenterList);
                            this.pService.done();
                        }
                    );

                }
            }
            else {
                if (ind >= 0) dataList[ind] = objData.Obj;
                else dataList.unshift(objData.Obj);

                if (objData.ObjType.toLowerCase() === "department") {
                    this.setDepartmentData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicetype") {
                    this.setServiceTypeData(dataList);
                } else if (objData.ObjType.toLowerCase() === "servicecenter") {
                    this.setServiceCenterData(dataList);
                }
            }
        }
    }

    setServiceCenterData(dataList: ServiceCenter[]) {
        this.serviceCenterList = [];
        this.serviceCenterList.push({ value: null, label: '- Select Service Center -' });
        if (dataList) {
            dataList.map(x => this.serviceCenterList.push({ value: x.ScID, label: x.ScN }));
        }
    }

    setServiceTypeData(dataList: ServiceType[]) {
        this.serviceTypeList = [];
        this.serviceTypeList.push({ value: null, label: '- Select Service Type -' });
        if (dataList) {
            dataList.map(x => this.serviceTypeList.push({ value: x.STID, label: x.STN }));
        }
    }

    setDepartmentData(dataList: Department[]) {
        this.departmentList = [];
        this.departmentList.push({ label: '- Select Department -', value: null });
        if (dataList) {
            dataList.map(x => this.departmentList.push({ value: x.DID, label: x.DN }));
        }
    }

    ngOnDestroy() {
        this.periods = this.types = this.oServiceCenterList = this.serviceCenterList = this.oServiceTypeList = this.serviceTypeList = this.oDepartmentList = this.departmentList =
            this.selectLists = this.dataList = this.dquery = this.joblistnewqry = this.barData = this.pieData = this.startPBar = this.donePBar = undefined;

        if (this.srObjT !== undefined) this.srObjT.unsubscribe();
        if (this.srObjSM !== undefined) this.srObjSM.unsubscribe();
        if (this.srObjJ !== undefined) this.srObjJ.unsubscribe();
        if (this.scChanged !== undefined) this.scChanged.unsubscribe();
        if (this.stChanged !== undefined) this.stChanged.unsubscribe();
        if (this.depChanged !== undefined) this.depChanged.unsubscribe();
    }

    getDataList(): void {
        if (this.dquery && this.dquery.FromDate && this.dquery.ToDate) {
            this.httpservice.postData('api/admindashboardtable', this.dquery).subscribe(
                exp => { this.dataList = exp },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', err);
                    this.pService.done();
                },
                () => {
                    this.pService.done();
                    this.filterData();
                });
        }
    }

    filterData() {
        if (!this.search) this.search = '';
        this.filterDataList = this.dataList;

        this.filterDataList = this.filterDataList.filter(j => (j.STN.trim()).toLowerCase().indexOf(this.search.toLowerCase().trim()) !== -1);
    }

    setSelectLists(dataList: any[], isServiceCenter = false) {
        this.selectLists = [];
        let id = isServiceCenter ? 'ScID' : 'STID';
        let lbl = isServiceCenter ? 'ScN' : 'STN';
        dataList.map(d => {
            this.selectLists.push({ value: d[id], label: d[lbl] });
        });

        if (dataList.length > 0) this.dquery.Selected = dataList[0][id];
        this.refreshChart();
    }

    changeType(): void {
        this.dquery.Selected = undefined;
        if (this.dquery.PType == 'st') {
            this.setSelectLists(this.oServiceTypeList);
        } else if (this.dquery.PType == 'sc') {
            this.setSelectLists(this.oServiceCenterList, true);
        }
    }

    periodChanged(): void {
        if (this.dquery.Period === 'f') {
            this.DefaultDateTime();
        }
        this.getDataList();
        this.refreshChart();
    }

    onSelectDate(): void {
        this.getDataList();
    }

    refreshChart(): void {
        //get data from server
        if (this.dquery && this.dquery.FromDate && this.dquery.ToDate) {
            this.pService.start();
            this.httpservice.postData('api/admindashboarddata', this.dquery).subscribe(
                exp => { this.barData = exp; },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', err);
                    this.pService.done();
                },
                () => {

                    setTimeout(() => {
                        this.barChart.refresh();
                        this.pieChart.refresh();
                    }, 1000)
                });

            this.httpservice.postData('api/admindashboardpiedata', this.dquery).subscribe(
                exp => {
                    this.pieData = exp;

                    // this.pieData = {
                    //     labels: ['Q1', 'Q2', 'Q3', 'Q4'],
                    //     datasets: [
                    //         {
                    //             label: 'Sales',
                    //             data: [540, 325, 702, 620],
                    //             backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
                    //             borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
                    //             borderWidth: 1
                    //         }
                    //     ]
                    // };
                },
                err => {
                    this.httpservice.showToast(this.toastkey, 'error', err);
                    this.pService.done();
                },
                () => {
                    // Observable.interval(1000).take(1).subscribe(x => { this.pieChart.refresh(); });
                    setTimeout(() => {
                        this.pieChart.refresh();
                    }, 1000);
                    this.pService.done();
                });
        }
    }

    exportToCSV() {
        this.dquery.SearchText = this.search;
        this.httpservice.postData('api/exportadmindashboardtable', this.dquery).subscribe(
            data => { },
            err => { },
            () => {
                // window.location.href = this.httpservice._url + 'excel/dashboardjobs.csv';
                this.httpservice.DirectLocToWindow("dashboardjobs");
            }
        );
    }

    ScDownOnChange(event) {
        this.dquery.SCID = event.value;
        this.getDataList();
    }

    STDownOnChange(event) {
        this.dquery.STID = event.value;
        this.getDataList();
    }

    DeptDownOnChange(event) {
        this.dquery.DID = event.value;
        this.getDataList();
    }

    // cellClick(colheader, rdata) {
    //     if(rdata == null || rdata == undefined || rdata.ReceivedJob == 0) return;
    //     var tmp: number = 0;
    //     this.joblistnewqry.departmentId = rdata.DID;
    //     this.joblistnewqry.servicecenterId = rdata.ScID;
    //     this.joblistnewqry.servicetypeId = rdata.STID;
    //     this.joblistnewqry.period = this.dquery.Period;
    //     this.joblistnewqry.fromdate = this.dquery.FromDate;
    //     this.joblistnewqry.todate = this.dquery.ToDate;
    //     this.joblistnewqry.noTaken = false;
    //     this.joblistnewqry.noSDone = false;

    //     switch (colheader) {
    //         case 'Received job': {
    //             this.joblistnewqry.jobstatusId = 1;
    //             this.title = 'Job Detail List (Received Job)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.ReceivedJob;
    //             break;
    //         }
    //         case 'Done': {
    //             this.joblistnewqry.jobstatusId = 2;
    //             this.title = 'Job Detail List (Done)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.Done;
    //             break;
    //         }
    //         case 'Taken': {
    //             this.joblistnewqry.jobstatusId = 4;
    //             this.title = 'Job Detail List (Taken)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.Taken;
    //             break;
    //         }
    //         case 'Work in progress': {
    //             this.joblistnewqry.jobstatusId = 5;
    //             this.title = 'Job Detail List (Work In Progress)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.WorkInProgress;
    //             break;
    //         }
    //         case 'Warranty': {
    //             this.joblistnewqry.jobstatusId = 6;
    //             this.title = 'Job Detail List (Warranty)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.Warranty;
    //             break;
    //         }
    //         case 'Waiting Reply': {
    //             this.joblistnewqry.jobstatusId = 7;
    //             this.title = 'Job Detail List (Customer Reply)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.CustomerReply;
    //             break;
    //         }
    //         case 'FOC': {
    //             this.joblistnewqry.jobstatusId = 8;
    //             this.title = 'Job Detail List (FOC)';
    //             this.isJobDetailList = true;
    //             tmp = rdata.FOC;
    //             break;
    //         }
    //     }
    //     if (tmp !== 0) this.jobDetailListModal.open();
    // }

    cellClick(colfield, rdata) {
        if (rdata == null || rdata == undefined) return;
        var tmp: number = 0;
        this.joblistnewqry.departmentId = rdata.DID;
        this.joblistnewqry.servicecenterId = rdata.ScID;
        this.joblistnewqry.servicetypeId = rdata.STID;
        this.joblistnewqry.period = this.dquery.Period;
        this.joblistnewqry.fromdate = this.dquery.FromDate;
        this.joblistnewqry.todate = this.dquery.ToDate;
        this.joblistnewqry.noTaken = false;
        this.joblistnewqry.noSDone = false;

        switch (colfield) {
            case 'ReceivedJob': {
                this.joblistnewqry.jobstatusId = 1;
                this.title = 'Job Detail List (Received Job)';
                this.isJobDetailList = true;
                tmp = rdata.ReceivedJob;
                break;
            }
            case 'WorkInProgress': {
                this.joblistnewqry.jobstatusId = 5;
                this.title = 'Job Detail List (Work In Progress)';
                this.isJobDetailList = true;
                tmp = rdata.WorkInProgress;
                break;
            }
            case 'Warranty': {
                this.joblistnewqry.jobstatusId = 6;
                this.title = 'Job Detail List (Warranty)';
                this.isJobDetailList = true;
                tmp = rdata.Warranty;
                break;
            }
            case 'CustomerReply': {
                this.joblistnewqry.jobstatusId = 7;
                this.title = 'Job Detail List (Customer Reply)';
                this.isJobDetailList = true;
                tmp = rdata.CustomerReply;
                break;
            }
            case 'Done': {
                this.joblistnewqry.jobstatusId = 2;
                this.title = 'Job Detail List (Done)';
                this.isJobDetailList = true;
                tmp = rdata.Done;
                break;
            }
            case 'Taken': {
                this.joblistnewqry.jobstatusId = 4;
                this.title = 'Job Detail List (Taken)';
                this.isJobDetailList = true;
                tmp = rdata.Taken;
                break;
            }
            case 'FOC': {
                this.joblistnewqry.jobstatusId = 8;
                this.title = 'Job Detail List (FOC)';
                this.isJobDetailList = true;
                tmp = rdata.FOC;
                break;
            }
        }

        if (tmp !== 0) this.jobDetailListModal.open();
    }

    jDListModalClose() {
        this.joblistnewqry = new JobListNewQuery(null, null, null, null, 'a', this.dquery.FromDate, this.dquery.ToDate);
        this.title = '';
        this.isJobDetailList = false;
        // this._ngZone.run(() => this.getDataList());
        this.jobDetailListModal.close();

    }

    DefaultDateTime() {
        this.dquery.FromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0);
        this.dquery.ToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 60);
    }
}